import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { GrNext } from "react-icons/gr";
import { GrPrevious } from "react-icons/gr";
import { BiStar } from "react-icons/bi";
import "../css/ReviewSlider.css";
import Carousel from "react-bootstrap/Carousel";

const Review = () => (
  <div className="review">
    <BiStar size={25} fill="#FFD700" />
    <BiStar size={25} fill="#FFD700" />
    <BiStar size={25} fill="#FFD700" />
    <BiStar size={25} fill="#FFD700" />
    <BiStar size={25} fill="#FFD700" />
  </div>
);

function ReviewSlider() {
  const [activeSlide, setActiveSlide] = useState(0);
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    // Fetch testimonials data from the API
    const fetchTestimonials = async () => {
      try {
        const response = await fetch("https://api.lissomtech.in/get_testimonials");
        const data = await response.json();
        setTestimonials(data);
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };

    fetchTestimonials();
  }, []);

  useEffect(() => {
    // Get a reference to your carousel element
    const carousel = document.getElementById("myCarousel");
    carousel.addEventListener("slide.bs.carousel", (event) => {
      setActiveSlide(event.to);
    });
  }, []);

  return (
    <div className="container" style={{ marginTop: "40px", marginBottom: "10px" }}>
      <div className="row">
        <h3>What Client Says</h3>
        <div id="myCarousel" className="carousel slide mb-6" data-bs-ride="carousel" data-bs-interval="2400">
          <div className="carousel-inner">
            {testimonials.map((testimonial, index) => (
              <Carousel.Item key={index} className={index === 0 ? "active" : ""}>
                <div className="carousel-caption center-text">
                  <p>{testimonial.Comment}</p>
                  <span>
                    {testimonial.Client_name}
                    <p className="role">{testimonial.Client_position}</p>
                    <p className="role">{testimonial.Address}</p>
                    <Review />
                  </span>
                </div>
              </Carousel.Item>
            ))}
          </div>
          <div className="custom-indicators" style={{ marginBottom: "0px" }}>
            {testimonials.map((_, index) => (
              <button
                key={index}
                type="button"
                data-bs-target="#myCarousel"
                data-bs-slide-to={index}
                className={`dot ${activeSlide === index ? "active" : ""}`}
                aria-label={`Slide ${index + 1}`}
              ></button>
            ))}
          </div>

          <a className="carousel-control-prev" href="#myCarousel" role="button" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true">
              <GrPrevious />
            </span>
          </a>
          <a className="carousel-control-next" href="#myCarousel" role="button" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true">
              {" "}
              <GrNext />
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ReviewSlider;
