// import React, { useState, useEffect } from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import axios from "axios";
// import { Card, Container, Row, Col, Form } from "react-bootstrap";
// const Carousel = () => {
//   const [galleryData, setGalleryData] = useState([]);

//   useEffect(() => {
//     fetchGalleryData();
//   }, []);

//   const fetchGalleryData = async () => {
//     try {
//       const response = await axios.get("https://api.lissomtech.in/api/getclients");
//       console.log("Fetched Gallery Data:", response.data);
//       setGalleryData(response.data);
//     } catch (error) {
//       console.error("Error fetching gallery data:", error);
//     }
//   };

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     autoplay: true, // Enable autoplay
//     autoplaySpeed: 2000,
//     beforeChange: (current, next) => {
//       console.log("Before Change:", current, next);
//     }, // Set autoplay speed in milliseconds (e.g., 2000ms or 2s)
//   };

//   return (
//     <div>
//       <h1 className="ourclient text-center" style={{ marginTop:"30px"}}>Our-clients</h1>
//       <br />
//       <Slider {...settings}>
//         {galleryData.map((data) => (
//             <div  className="col-md-3 md-6">
//           <Card style={{ width: "18rem"  ,marginTop:"30px"}}>
//             <Card.Img
//               variant="top"
//               src={`data:image/png;base64,${data.Ourclients}`}
//               style={{ height: "200px", objectFit: "cover", width: "100%" }}
//               key={data.id}
//             />
//           </Card>
//           </div>
//         ))}
//       </Slider>
//     </div>
//   );
// };

// export default Carousel;











import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { Card, Container, Row, Col, Form } from "react-bootstrap";

const Carousel = () => {
  const [galleryData, setGalleryData] = useState([]);
  const [slidesToShow, setSlidesToShow] = useState(4); 

  useEffect(() => {
    fetchGalleryData();
    updateSlidesToShow(); 
    window.addEventListener("resize", updateSlidesToShow);
    return () => window.removeEventListener("resize", updateSlidesToShow);
  }, []);

  const fetchGalleryData = async () => {
    try {
      const response = await axios.get("https://api.lissomtech.in/api/getclients");
     
      setGalleryData(response.data);
    } catch (error) {
      console.error("Error fetching gallery data:", error);
    }
  };

  const updateSlidesToShow = () => {
    
    const isMobile = window.innerWidth < 768;
    setSlidesToShow(isMobile ? 3 : 4);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow, 
    slidesToScroll: 1,
    autoplay: true, 
    autoplaySpeed: 1500,
    beforeChange: (current, next) => {
    
    }, 
  };

  const [imageHeight, setImageHeight] = useState("200px");

  useEffect(() => {
    fetchGalleryData();
    updateImageHeight();
    window.addEventListener("resize", updateImageHeight);
    return () => window.removeEventListener("resize", updateImageHeight);
  }, []);

  const updateImageHeight = () => {
        const newHeight = window.innerWidth < 768 ? "100px" : "200px";
        setImageHeight(newHeight);
      };

  return (
    <div className="container">
      <h1 className="ourclient text-center" style={{ marginTop: "30px" }}>
        Our-clients
      </h1>
      <br />

      <div className="row">
        <Slider {...settings}>
          {galleryData.map((data) => (
            <div>
              <Card style={{ margin: "10px" }}>
                <Card.Img
                  variant="top"
                  src={`data:image/png;base64,${data.Ourclients}`}
                  style={{ height: imageHeight, objectFit: "cover", width: "100%" }}
                  key={data.id}
                />
              </Card>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Carousel;