
import React, { useEffect, useState } from "react";

import "../css/Aboutus.css";
import Footer from "../components/Footer";
import axios from "axios";
function Aboutus() {
  const [aboutData, setAboutData] = useState([]);
  useEffect(() => {
    axios
      .get("https://api.lissomtech.in/api/getaboutus")
      .then((response) => {
        console.log("Fetched Data:", response.data);
        setAboutData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const [founderData, setFounderData] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.lissomtech.in/Founders")
      .then((response) => {
        console.log("Fetched Founder Data:", response.data);
        setFounderData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching founder data:", error);
      });
  }, []);

  return (
    <>
      <div className="container-fluid" style={{ marginTop: "100px" }}>
        {aboutData.map((data, index) => (
          <div className="image-container" key={index}>
            <img
              src={`data:image/jpeg;base64,${data.Aboutus_img}`}
              className="card-img"
              alt="Image 2"
              style={{ height: "380px" }}
            />
            <div className="text-start">
              <h1
                class="text"
                style={{
                  fontFamily: "Montseerrat",
                  fontSize: "40px",
                  color: "#5e2200",
                  fontWeight: "800",
                }}
              >
                WE PROMISE
              </h1>
              <h5
                className="text"
                style={{
                  fontFamily: "Montseerrat",
                  fontSize: "20px",
                  color: "#5e2200",
                  fontWeight: "400",
                }}
              >
                Quality | Perfection | Customer Satisfaction
              </h5>
            </div>
          </div>
        ))}
        <br></br>

        <div class="card info col-md-12" style={{border:'none'}}>
          {aboutData.map((data, index) => (
            <div class="card-body">
              <h1
                style={{
                  fontFamily: "Montseerrat",
                  fontSize: "30px",
                  color: "black",
                  fontWeight: "400",
                  textAlign: "center",
                }}
              >
                About Us
              </h1>
              <p
                style={{
                  fontFamily: "Montseerrat",
                  fontSize: "16px",
                  
                  color: "#666666",
                  fontWeight: "500",
                }}
              >
                {data.About_Description}
              </p>
            </div>
          ))}
        </div>

        <div className="founder-info">
          <div className="line"></div>
          <div className="centered-text">
            <h2>Our Founder's</h2>
          </div>
          <div className="line"></div>
        </div>

        {founderData.map((founder, index) => (
          <div className="row" key={index}>
            <div
              class="card mb-3"
              style={{
                maxWidth: "800px",
                marginLeft: index % 2 === 1 ? "auto" : "0",
                marginRight: index % 2 === 0 ? "auto" : "0",
              }}
            >
              <div class="row g-0">
                <div class="col-md-6">
                  <img
                    src={`data:image/png;base64,${founder.Founders_img}`}
                    class="img-fluid rounded-start"
                    alt="..."
                    style={{ padding: "20px", paddingTop: "55px" }}
                  />
                </div>
                <div class="col-md-6">
                  <div class="card-body">
                    <p
                      class="card-title"
                      style={{
                        fontFamily: "Montseerrat",
                        fontSize: "30px",
                        fontWeight: "400",
                        color: "hsl(21, 62%, 45%)",
                      }}
                    >
                      {founder.Founders_Name}
                    </p>
                    <p
                      class="card-title"
                      style={{
                        fontFamily: "Montseerrat",
                        fontSize: "20px",
                          color: "black",
                        fontWeight: "400",
                      }}
                    >
                      {founder.Founders_Role}
                    </p>
                    <p
                     
                      style={{
                        fontFamily: "Montseerrat",
                        color: "#666666",
                        fontSize: "18px",
                        fontWeight: "400",
                       
                      }}
                    >
                      {founder.Founders_Description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        {/* <div className="col-md-6" style={{ padding: "20px", paddingTop: "10px", height: "20px" }}></div> */}
      </div>

      <Footer />
    </>
  );
}
export default Aboutus;
